import { useEffect, useRef, useState } from 'react'

export function useSelectFirstInput() {
    const [isSelected, setIsSelected] = useState(false)
    const modalBodyRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (isSelected || !modalBodyRef.current) {
            return
        }

        const inputs = modalBodyRef.current.querySelectorAll('input, select, textarea')
        if (inputs.length === 0) {
            return
        }

        const firstInput = inputs[0] as HTMLElement
        firstInput.focus()
        setIsSelected(true)
    }, [isSelected, modalBodyRef])

    return { modalBodyRef }
}
